import { template as template_7d8ded0f530a413d991e08115ebea236 } from "@ember/template-compiler";
const FKLabel = template_7d8ded0f530a413d991e08115ebea236(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
