import { template as template_786b7ff512094e44b35df998859e7d5f } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_786b7ff512094e44b35df998859e7d5f(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
