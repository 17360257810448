import { template as template_942f33751c234e01b91da4e4a3e12ad1 } from "@ember/template-compiler";
const SidebarSectionMessage = template_942f33751c234e01b91da4e4a3e12ad1(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
